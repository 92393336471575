import { useState, useEffect, createContext, useContext } from 'react';
import { useReducedMotion } from '@react-spring/web';
import { useTrack } from 'src/contexts/AnalyticsContext';
import { PERFORMANCE_EVENTS } from 'src/lib/trackingEvents';
import useDeviceSize from './useDeviceSize';
import { isDevelopment } from 'src/lib/constants';
import _debounce from 'lodash/debounce';

const PerformanceContext = createContext<boolean>(false);

export const PerformanceProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const track = useTrack();
  const [isLowPerformance, setIsLowPerformance] = useState(false);
  const prefersReducedMotion = useReducedMotion();

  const device = useDeviceSize();

  useEffect(() => {
    const iterations = 1e7;
    const warmUp = () => {
      let total = 0;
      for (let i = 0; i < iterations; i++) {
        total += Math.sqrt(i);
      }
      return total;
    };

    warmUp();

    const runBenchmark = () => {
      const start = performance.now();
      warmUp();
      return performance.now() - start;
    };

    const trials = 5;
    const times: number[] = [];
    for (let i = 0; i < trials; i++) {
      times.push(runBenchmark());
    }
    const avgTime =
      times.reduce((a, b) => {
        return a + b;
      }, 0) / trials;

    const isMobile = device.width <= 1024;
    let isLowCpuPerformance: boolean;

    if (!isDevelopment) {
      isLowCpuPerformance = isMobile ? avgTime > 8 : avgTime > 11;
    } else {
      isLowCpuPerformance = isMobile ? avgTime > 100 : avgTime > 12;
    }

    setIsLowPerformance(prefersReducedMotion || isLowCpuPerformance);
  }, [device.width, prefersReducedMotion, isDevelopment]);

  useEffect(() => {
    const debouncedTrack = _debounce(() => {
      if (isLowPerformance) {
        track({ type: PERFORMANCE_EVENTS.IS_LOW_PERFORMANCE });
      } else {
        track({ type: PERFORMANCE_EVENTS.IS_NOT_LOW_PERFORMANCE });
      }
    }, 500);

    debouncedTrack();

    return () => {
      debouncedTrack.cancel();
    };
  }, [track, isLowPerformance]);

  return (
    <PerformanceContext.Provider value={isLowPerformance}>
      {children}
    </PerformanceContext.Provider>
  );
};

const useIsPotato = () => {
  try {
    const isLowPerformance = useContext(PerformanceContext);
    return isLowPerformance;
  } catch (error) {
    return true;
  }
};

export default useIsPotato;
